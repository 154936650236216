.swiper {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  /* .swiper-large {
    width: 350px;
    height: 571px;
  } */
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .swiper img {
    width: 100%;
    height: 100%;
    /* height: 100%; */
    /* object-fit: cover; */
    /* max-height: 571px; */
  }

  .swiper-button {
    font-size: 40px;
    cursor: pointer;
    text-shadow: 2px 2px 2px rgb(144, 106, 3);
    transition: transform 0.2s ease-in-out;
    /* z-index: 10; */
  }