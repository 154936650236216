.bottom-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 100%;
    height: 60px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(24, 3, 67);
    color: rgb(214, 158, 3);
  }
  
  .bottom-nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
  }
  
  .bottom-nav-title {
    font-weight: bold;
    margin-left: 20px;
  }
  
  .bottom-nav-trademark {
    font-size: 16px;
    font-weight: bold;
  }
  
  .bottom-nav-right {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
  }

  .bottom-top-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: rgb(214, 158, 3);
  }
  
  .bottom-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    color: rgb(255, 255, 255);
    margin-left: 20px;
    transition: all 0.2s ease-in-out;
  }
  
  .bottom-nav-link:hover {
    color: rgb(214, 158, 3);
    text-decoration: underline;
  }
  