.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  line-height: 2em;
  border: solid 4px rgb(214, 158, 3);
}

.about-header {
  color: white;
  font-size: 3rem;
}

.about-text {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
}

.about-text span {
  font-size: 24px;
  margin: 0 8px;
}

.underline {
  text-decoration: underline;
  color: rgb(214, 158, 3);
}

.about-important-text {
  font-size: 24px;
  font-weight: bold;
  margin: 0 8px;
  background-clip: text;
  background: linear-gradient(to right, #6f00ff, #0077c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.past {
  background: linear-gradient(to right, #ff0000, #dfe444);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.present {
  background: linear-gradient(to right, #11ff00, #317ced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.future {
  background: linear-gradient(to right, #9900ff, #e9187d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
  .about-container {
    height: 100%;
  }
}
