.App-container {
  /* height: 100vh; */
}

.App {
  height: 100vh;
  /* text-align: center; */
  /* background-image: url('./images/background.jpeg'); */
  background-size: cover;
  background-position: center center;
  /* position: relative; */
  /* opacity: .8; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* margin-bottom: 50px; */
  /* height: 100% */
}

.title {
  font-family: 'Dancing Script', cursive;;
  text-align: center;
  margin: 0;
  /* margin-top: -50px; */
  /* margin-bottom: 20px; */
  font-size: min(15vw, 60px);
  /* position: absolute;
  top: 10.5%;
  left: 49.9%; */
  /* transform: translate(-50%, -50%); */
  color: rgb(24, 3, 67);
  text-shadow: 0 0 10px rgb(214, 158, 3);
}

.clock {
  font-family: 'Dancing Script', cursive;;
  text-align: center;
width: 90%;
  /* position: absolute;
  top: 15%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  color: rgb(24, 3, 67);
  text-shadow: 0 0 10px rgb(214, 158, 3);
  font-size: min(8vw, 30px);
  /* width: 100%; */
}

.generated-image {
  width: 55%;
}

.interpretation-container, .faq-container, .options-container, .download-container, .setup-container {
  margin-top: 40px;
  background-color: rgb(24, 3, 67);
  border: solid 6px #E8BA7C;
  color: rgb(214, 158, 3);
  width: 1050px;
  height: 700px;
  overflow: scroll;
  display: flex; 
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  border-radius: 10px;
  box-sizing: border-box;
    margin-bottom: 25px;
    /* position: relative;
    z-index: 10 ; */

}

.options-container, .download-container {
  justify-content: center;
}

.setup-container {
  height: 80vh;
  justify-content: center;
  overflow: hidden;
  margin-top: 20px;
}

.swiper-button-row {
  margin-bottom: -45px;
  /* position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex; */
}

.interpretation-container-shareable {
  background-color: rgb(24, 3, 67);
  /* border: solid 6px #E8BA7C; */
  color: rgb(214, 158, 3);
  width: 750px;
  height: 800px;
  overflow: scroll;
  display: flex; 
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  border-radius: 10px;
  box-sizing: border-box;
    /* margin-bottom: 25px; */
}
.interpretation-text-shareable {
  width: 90%;
  margin: 0px;
}

.interpretation-text-shareable p:last-child {
  margin: 5px;
}



.share-container {
  /* background-color: rgb(24, 3, 67); */
  /* border-left: solid 6px #E8BA7C; */
  /* border-right: solid 6px #E8BA7C; */
  /* border-bottom: solid 6px #E8BA7C; */

  color: rgb(214, 158, 3);
  width: 800px;
  height: 450px;
  overflow: scroll;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  /* border-radius: 10px; */
  box-sizing: border-box;
  /* background: linear-gradient(to right, #000000, #660066, #993399, #663399, #330066, #000000) */

  position: absolute;
  top: 0;
  left: -9999px;
    /* margin-bottom: 25px; */
}


.interpretation-text {
  width: 90%;
}

.interpretation-header, .faq-header, .options-header, .share-header, .setup-header {
  /* text-decoration: underline; */
  font-family: 'Dancing Script', cursive;;
  text-align: center;
  font-size: 48px;
}

.setup-header {
  font-size: 40px;
}

.text-top {
  /* margin-top: 20px; */
}
/* .card-placeholder {
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .9;
} */

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* gap: 40px; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* width: 90%; */
  /* margin-bottom: 50px; */
    margin-bottom: 25px;
    z-index: 5;

}

.card-container-shareable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card {
  width: 350px;
  height: auto;
  max-height: 571px;
  /* background-color: rgb(24, 3, 67); */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(214, 158, 3);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  opacity: 1;
  transition: all 1s ease-in-out;
  border: solid 6px #E8BA7C;
  box-sizing: border-box;
  /* border: 10px solid transparent; */
  /* border-image: linear-gradient(to right, #1a1a2e, #16213e, #1b2a41, #193742, #10466a, #2e5b8d, #5c5d5d) 1 !important; */
  /* background: linear-gradient(to right, #1a1a2e, #16213e, #1b2a41, #193742, #10466a, #2e5b8d, #5c5d5d) !important; */
  /* position: relative; */

  /* position: absolute; 
  top: 0;
  left: 0;
  transform: translate(-50%, -50%); */
}

/* .card::after {
  content: "->";
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 30px;
  color: white;
  border-radius: 10px;
  z-index: 5;
  opacity: .9;
  transition: all 1s ease-in-out;
} */

/* .blink {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 251, 0);
  border-radius: 50%;
  animation: blink 1s ease-in-out infinite;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

.tool-tip {
  position: absolute;
  top: -60px;
  left: -60px;
  width: 100px;
  height: 100px;
  background-color: rgb(24, 3, 67);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 1s ease-in-out;
  z-index: 0;
  color: white;
  transform: rotate(-45deg);
}



.mobile-expand-button {
  display: none;
}

@media screen and (max-width: 600px) {
  .App {
    height: 100%;
    min-height: 100vh;
  }

  .title {
    margin-top: 15px;
  }
  .card {
    width: 125px;
    height: 208px;
    /* transition: all .3s ease-in-out !important; */
    border-radius: 10px;
    /* other mobile styles here */
    /* position: relative; */
  }

  
  .tarot-card-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 'opacity 0.2s ease-in-out';
    /* position: 'relative'; */
    z-index:  10;
    /* max-width: 50px; */
    /* gap: 20px; */
  }

  .mobile-expand-button {
      font-family: 'Dancing Script', cursive;;
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgb(24, 3, 67);
      color: rgb(214, 158, 3);
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 20px;
      box-shadow: 0 0 10px rgb(214, 158, 3);
      cursor: pointer;
      transition: transform .3s ease-in-out;
      margin-bottom: 20px;
      margin-top: 20px;
      /* box-shadow: black; */
  }

  .mobile-expand-button:disabled {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgb(24, 3, 67);
  }
    
  
    .mobile-expand-button:active {
      transform: translateY(5px) translate(-50%, -50%);
      box-shadow: 0 0 5px rgb(214, 158, 3);
      background-color:  rgb(214, 158, 3);
      color: rgb(24, 3, 67);
    }

    .expand-card {
      width: 359px;
      height: 100%;
      /* overflow: scroll !important; */
      /* margin-right: -250px; */
      /* position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
      /* background-color: rgb(24, 3, 67); */
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgb(214, 158, 3);
     
      opacity: 1;
      /* transition: transform 1s ease-in-out; */
      border: solid 6px #E8BA7C;
      box-sizing: border-box;
      transition: all 1s ease-in-out;
      /* z-index: 10; */
      /* border: 10px solid transparent; */
      /* border-image: linear-gradient(to right, #1a1a2e, #16213e, #1b2a41, #193742, #10466a, #2e5b8d, #5c5d5d) 1 !important; */
      /* background: linear-gradient(to right, #1a1a2e, #16213e, #1b2a41, #193742, #10466a, #2e5b8d, #5c5d5d) !important; */
      /* position: relative; */
    }

    .expand-card.card-center-expand {
      /* margin: auto; */
      /* margin-left: -100px; */
      margin-right: -250px;
      transform: translateX(-125px);
      /* position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%); */

    }


    .expand-card.card-left-expand {
      margin-right: -250px;
    }

    .expand-card.card-right-expand {
      margin-left: -250px;
    }

    .card-name {
      font-size: 28px;
    }
    
    .card-keyword {
      font-size: 18px;
    }
    
    .card-meaning {
      /* font-size: 10px !important; */
    }
  

  .interpretation-container, .options-container, .faq-container, .download-container, .setup-container {
    width: 100%;
    height: 100%;
    max-height: 500px;
    overflow: scroll;
    padding: 30px;
    /* margin-left: -5vw; */
  }

  .options-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 20px;
  }

  .options-tarot-back {
    width: 200px !important;
    /* height: 125px !important; */
  }

  .card-revealed {
    text-align: center;
    overflow: scroll;
    /* width: 1050px; */
  }

  .question-container {
    width: 300px !important;
    /* height: 208px !important; */
  }

  .select {
    width: 100px !important;
  }

  .swiper-mobile {
    width: 75px !important;
    height: 125px !important;
  }

  .options-column {
    /* margin-left: 25px !important; */
  }

  .button-next, .button-prev {
    position: absolute;
    bottom: 100px !important;; 
    /* right: 130; */
    font-size: 40px;
    cursor: pointer;
    text-shadow: 2px 2px 2px rgb(144, 106, 3);
    transition: transform 0.2s ease-in-out;
    z-index: 10;
  }
  
  .right {
    right: 45px !important;
  }
  
  .left {
    right: 75px !important;
  }

  .tool-tip {
    text-align: center;
    position: absolute;
    font-size: 16px;
    top: -50px;
    left: -5px;
    width: 50px;
    height: 50px;
    background-color: rgb(24, 3, 67);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all 1s ease-in-out;
    z-index: 0;
    color: rgb(214, 158, 3);
    transform: rotate(-20deg);
    font-family: Dancing Script, cursive;
  }

  .bottom-greeting {
    margin-bottom: 20px;
  }

  .button {
    flex: 1;
    width: 100% !important;
    /* min-width: none; */
  }

  .button-container-column{
    margin-top: 30px;
  }
}

.card-revealed {
  background-color: rgb(24, 3, 67);
  overflow: scroll;
  color: white;
  border: none;
  /* width: 1050px; */
  /* z-index: 10; */
  /* touch-action: none */
  /* fontSize: 12px */
  /* border-radius: 10px; */
}

.card-download {
  border: solid 6px #E8BA7C;
  width: 250px;
  height: 400px;
  /* background-color: rgb(24, 3, 67); */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(214, 158, 3);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  opacity: 1;
  transition: transform 2s ease-in-out;
  border: solid 6px #E8BA7C;
  box-sizing: border-box;
}

.card-download-title {
  /* margin-top: 95px; */
  z-Index: 1;
  color: black;
  font-size: 80px;
  margin-top: 0;
  font-family: Dancing Script, cursive;
  opacity: 0.5;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.card::-webkit-scrollbar {
  display: none;
}

.card.one {
  background-image: url('./images/card1.png');
  background-size: cover;
  background-position: center center;
}

.card.spinning {
  animation: spin 2s ease-in-out;
  animation-iteration-count: 1;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg)
    /* background-image: url('./images/card2.png'); 
    background-size: cover; */
  }
}

.card.two {
  background-image: url('./images/card2.png');
  background-size: cover;
  background-position: center center;
}

.card.three {
  background-image: url('./images/card3.png');
  background-size: cover;
  background-position: center center;
}

.button {
  font-family: 'Dancing Script', cursive;
  width: 140px;
  /* min-width: 140px; */
  /* position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background-color: rgb(24, 3, 67);
  color: rgb(214, 158, 3);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 20px;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  cursor: pointer;
  transition: transform .3s ease-in-out;
  /* box-shadow: black; */
}

/* .options-button {
  left: 30%;
}

.faq-button {
  left: 70%;
} */

.button:active {
  transform: translateY(5px);
  box-shadow: 0 0 5px rgb(214, 158, 3);
  background-color:  rgb(214, 158, 3);
  color: rgb(24, 3, 67);
}

.button:disabled {
  background-color: black;
}

.button:disabled:hover {
  background-color: black;
  color:rgb(214, 158, 3)
}

.button:hover {
  background-color:  rgb(214, 158, 3);
  color: rgb(24, 3, 67);
}

.button-action:active {
  transform: translateY(5px);
}

.button-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.button-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
}

.share-button {
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding: 10px 20px; */
}

/* .faq-button, .options-button, .start-button, .share-button {
  flex: 1; 
} */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.question-container {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: flex-start;
  width: 600px;
  /* width: '50%'; */
}

.question {
  font-size: 30px;
}

.question-row {
  display: flex;
  flex-direction: row;
  /* text-align: center; */
  /* justify-content: center; */
  align-items: baseline;
  gap: 10px;
  /* width: '90%'; */
}

.answer { 
  color: white;
  margin-left: 40px;
}

.answer-left {
    margin-left: 0px;
}

.answer-a, .question-q {
  font-family: 'Dancing Script', cursive;
  font-size: 22px;
}

.question-q {
  font-size: 32px;
}

.faq-header {
  text-decoration: underline;
}

.options-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  /* align-items: center; */
  gap: 30px;
}

.setup-tarot-back {
  width: 233px !important;
  height: 384px !important;
  margin-top: 0px !important;
}

.options-tarot-back {
  width: 350px;
  max-height: 571px;
  height: 571px;
  background-color: rgb(24, 3, 67);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(214, 158, 3);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  opacity: 1;
  transition: transform 2s ease-in-out;
  border: solid 6px #E8BA7C;
  box-sizing: border-box;
  margin-top: 40px;
}

.select {
  font-family: 'Dancing Script', cursive;;
  background-color: rgb(24, 3, 67);
  color: rgb(214, 158, 3);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 20px;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  cursor: pointer;
  transition: transform .3s ease-in-out;
  /* box-shadow: black; */
}

.border-select {
  /* height: 100%; */
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* z-index: 2; */
}


.card-meaning {
  width: 90%;
  margin-top: 30px;
  margin-bottom: 10px;
  white-space: pre-line;
  font-size: 14px;
}

.card-keyword {
    font-family: Dancing Script, cursive;
    margin: 0;
}

.card-name {
  text-decoration: underline;
  font-family: Dancing Script, cursive;
}

.bottom-greeting {
  font-family: Dancing Script, cursive;
  font-size: 30px;
  /* margin-top: 20px; */
  color: rgb(214, 158, 3);
  text-shadow: 2px 2px 5px rgb(24, 3, 67);
  text-align: center;
  /* padding: 20px; */
}

.close-button {
  position: absolute;
   top: 10px;
   right: 20px; 
   font-size: 30px; 
   cursor: pointer;
   transition: transform 0.2s ease-in-out;
}

.close-button:active {
  transform: translateY(2px);
}

.button-next, .button-prev {
  position: absolute;
  bottom: 325px; 
  /* right: 130; */
  font-size: 80px;
  cursor: pointer;
  text-shadow: 2px 2px 2px rgb(144, 106, 3);
  transition: transform 0.2s ease-in-out;
  z-index: 10;
}

.right {
  right: 30px;
}

.left {
  right: 385px;
}

.button-next:active, .button-prev:active {
  transform: translateY(2px);
}

