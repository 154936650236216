.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 2em;
}

.blog-header {
  color: white;
  font-size: 3rem;
}

.blog-date {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-top: -20px;
  text-align: center;
}

.blog-text {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
}

.blog-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  max-width: 800px;
  border: solid 2px rgb(214, 158, 3);
  background-color: rgb(24, 3, 67);
}

.blog-article-header {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  color: rgb(214, 158, 3);
}

.blog-article-subheader {
  color: rgb(214, 158, 3);
  text-align: left;
  margin-left: 20px;
}

.blog-article-text {
  font-size: 18px;
  line-height: 1.5;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
  text-indent: 2em;
}

.blog-article-link {
  font-size: 18px;
  margin: 20px 0;
  text-decoration: underline;
  color: rgb(214, 158, 3);
}

@media screen and (max-width: 600px) {
  .blog-container {
    height: 100%;
  }
}

.reading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.reading-header {
  font-size: 3rem;
  margin-bottom: 20px;
}
