.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: solid 4px rgb(214, 158, 3);
}

.support-header {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: rgb(214, 158, 3);
}

.support-text {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 20px;
}

.support-email {
  background-clip: text;
  background: linear-gradient(to right, #9248f3, #53bafa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.support-faq-container {
  border-top: solid 4px rgb(214, 158, 3);
}

.support-faq-header {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: rgb(214, 158, 3);
  text-align: center;
}

.support-faq-list {
  list-style: none;
  margin: 0;
}

.support-faq-question {
  font-size: 24px;
  font-weight: bold;
  margin: 40px 0 20px;
  background-clip: text;
  background: linear-gradient(to right, #6f00ff, #0077c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.support-faq-answer {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
  margin-right: 20px;
}

.support-faq-answer a {
  color: white;
  text-decoration: underline;
}
