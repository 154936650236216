.download-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .share-header {
    font-family: 'Dancing Script', cursive;;
    text-decoration: underline;
  }

  
  .download-types label {
    margin: 0 10px;
  }
  
  .download-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    /* margin-bottom: -10px; */
    /* gap: 20px; */
  }

  .share-and-download-container {
    color: rgb(214, 158, 3);
  width: 320px; 
  height: 180px;
  /* overflow: scroll; */
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgb(214, 158, 3);
  border-radius: 10px;
  box-sizing: border-box;
  }
  
.text-examples {
    height: 150px;
}

.transform-container {
    transform: scale(.4)
}

@media screen and (max-width: 600px) {
    .share-and-download-container {
      width: 160px;
      height: 90px;
    }

    .transform-container {
        transform: scale(.25)
    }

    /* .download-container {
        width: 100vw;
        height: 100%;
        max-height: 500px;
        overflow: scroll;
    } */
}